/* Divider between resources and time area
--------------------------------------------------------------------------------------------------*/
.fc-timeline .fc-divider {
  width: 3px;
  border-style: double;
  /* overcome neighboring borders */
}

.fc-timeline .fc-head > tr > .fc-divider {
  border-bottom: 0;
}

.fc-timeline .fc-body > tr > .fc-divider {
  border-top: 0;
}

/* Resource Area
--------------------------------------------------------------------------------------------------*/
.fc-resource-area {
  width: 30%;
}

.fc-resource-area col {
  width: 40%;
  min-width: 70px;
  /* will be read by JS */
}

.fc-resource-area col.fc-main-col {
  width: 60%;
  /* make the first column in a nested setup bigger */
}

.fc-flat .fc-expander-space {
  /* fc-flat is opposite of fc-nested */
  display: none;
}

.fc-ltr .fc-resource-area tr > * {
  text-align: left;
}

.fc-rtl .fc-resource-area tr > * {
  text-align: right;
}

.fc-resource-area .fc-cell-content {
  padding-left: 4px;
  padding-right: 4px;
}

/* head */
.fc-resource-area .fc-super th {
  text-align: center;
}

.fc-resource-area th > div {
  position: relative;
}

.fc-resource-area th .fc-cell-content {
  position: relative;
  z-index: 1;
}

.fc-resource-area th .fc-col-resizer {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  width: 5px;
}

.fc-timeline .fc-col-resizer {
  cursor: col-resize;
}

.fc-ltr .fc-resource-area th .fc-col-resizer {
  right: -3px;
}

.fc-rtl .fc-resource-area th .fc-col-resizer {
  left: -3px;
}

/* body */
.fc-body .fc-resource-area .fc-cell-content {
  /* might BE the cell */
  padding-top: 8px;
  padding-bottom: 8px;
}

.fc-no-overlap .fc-body .fc-resource-area .fc-cell-content {
  /* might BE the cell */
  padding-top: 6px;
  padding-bottom: 6px;
}

.fc-resource-area .fc-icon {
  /* the expander and spacers before the expander */
  display: inline-block;
  width: 1em;
  /* ensure constant width, esp for empty icons */
  text-align: center;
}

.fc-resource-area .fc-expander {
  cursor: pointer;
  opacity: 0.65;
}

/* body resource rows */
.fc-time-area .fc-rows {
  position: relative;
  z-index: 3;
}

.fc-time-area .fc-rows td > div {
  position: relative;
}

.fc-time-area .fc-rows .fc-bgevent-container,
.fc-time-area .fc-rows .fc-highlight-container {
  z-index: 1;
}
